/**
* hidden: true,               是否隐藏路由
* path: '/welcome',           路由地址
* component:'Layout',         vue文件路径
* redirect: noredirect,       路由重定向
* name:'welcome',             路由名称父级可不填
* meta : {                    单层级路由父级可不填
   role: ['admin','editor'],    页面权限
   title: 'title'               页面标题
   icon: 'svg-name'             页面图标
   breadcrumb: false,           如果设置为false，则不会在breadcrumb面包屑中显示(默认 true)
   keepAlive: false,            页面是否缓存
 }
**/

export default [
  // 首页
  {
    path: '/',
    redirect: '/home',
    component: 'Layout',
    meta: {
      title: '首页',
      icon: 'workplace',
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: '/home/Index'
      }
    ]
  },
  // 公司管理
  {
    path: '/companyManage',
    component: 'Layout',
    redirect: '/companyManage/index',
    meta: {
      title: '公司管理',
      icon: 'components',
    },
    children: [
      {
        path: '/companyManage/index',
        name: 'companyManage',
        component: '/companyManage/Index'
      },
      // {
      //   hidden: true,
      //   path: 'customerInfo',
      //   name: 'customerInfo',
      //   meta: {
      //     title: '公司详情',
      //   },
      //   component: '/groupManage/customerInfo/index',
      // }
    ]
  },
  // 经纪人管理
  {
    path: '/customerManage',
    component: 'Layout',
    redirect: '/customerManage/index',
    meta: {
      title: '经纪人管理',
      icon: 'questionnaire',
    },
    children: [
      {
        path: '/customerManage/index',
        name: 'customerManage',
        component: '/customerManage/Index'
      },
      {
        hidden: true,
        path: 'customerInfo',
        name: 'customerInfo',
        meta: {
          title: '经纪人详情',
        },
        component: '/customerManage/customerInfo/index',
      }
    ]
  },
  // 协议管理
  // {
  //   path: '/agreement',
  //   component: 'Layout',
  //   redirect: '/agreement/index',
  //   meta: {
  //     title: '协议管理',
  //     icon: 'workplace',
  //   },
  //   children: [
  //     {
  //       path: '/agreement/index',
  //       name: 'agreement',
  //       component: '/agreementManage/Index'
  //     }
  //   ]
  // },
  // 日志管理
  {
    path: '/log',
    component: 'Layout',
    redirect: '/log/withdrawal/index',
    meta: {
      title: '日志管理',
      icon: 'system',
    },
    children: [
      {
        meta: {
          title: '提现记录',
        },
        path: 'withdrawal/index',
        name: 'withdrawal',
        component: '/logManage/withdrawal/Index'
      },
      {
        meta: {
          title: '批次记录',
        },
        path: 'batch/index',
        name: 'batch',
        component: '/logManage/batch/Index'
      }
    ]
  },
  // 用户管理
  {
    path: '/user',
    component: 'Layout',
    redirect: '/user/index',
    meta: {
      title: '员工管理',
      icon: 'user',
    },
    children: [
      {
        path: '/user/index',
        name: 'user',
        component: '/userManage/Index'
      }
    ]
  },
  // 审核管理
  {
    path: '/formwork',
    component: 'Layout',
    redirect: '/formwork/index',
    meta: {
      title: '结佣管理',
      icon: 'system',
    },
    children: [
      {
        path: '/formwork/index',
        name: 'formwork',
        component: '/formwork/index'
      }
    ]
  },
]
